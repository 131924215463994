import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Config from '../../../../config';
import './styles.css'; // Import the CSS file

function CountdownTimer({ data }) {
  const countDownDate = new Date(`${data.waitingDate} ${data.time}`).getTime();

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0, expired: true };
    }
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds, expired: false };
  }

  return (
    <>

      <section class="landing-two-section_Timer">
        <div class="landing-two-container_Timer">
          <div class="landing-two-row_Timer">
            <div class="landing-two-Col_Timer  ">
              <div class="landing-two-col_Timer_image  ">
                <div class="landing-two-image_widget_Timer">
                  {data.card_image && (<img src={`${Config.mediaURL}/${data.card_image}`} style={{ width: "100%" }} alt=" " />)}
                </div></div></div>
            <div class="landing-two-Col_Timer">
              <div class="landing-two-content_wrap_Timer">
                <div class="landing-two-section_heading_wrap_Timer">
                  <h2 class="landing-two-heading_text_Timer">{data.Base.Title_CountdownHead}</h2>
                  <p class="landing-two-heading_description_Timer "> {data.Base.Countdown_Description}</p>
                </div>
                <div class="landing-two-Deals_countdowN_Timer ">
                  <ul class="landing-two-CountdowN_TimeR landing-two-UNordered_List">
                    {timeRemaining.expired ? <li class="landing-two-days_count" style={{ textAlign: 'center', width: "100%" }}><strong>    {data.Base.expired}</strong><span></span></li> :
                      <>
                        <li className='landing-two-li' ><strong> {timeRemaining.days}</strong><span className='landing-two-span_Days'>Days</span></li>
                        <li className='landing-two-li' ><strong class="landing-two-DAsh">:</strong></li>
                        <li className='landing-two-li' ><strong> {timeRemaining.hours}</strong><span className='landing-two-span_Days'>Hours</span></li>
                        <li className='landing-two-li' ><strong class="landing-two-DAsh">:</strong></li>
                        <li className='landing-two-li'   ><strong> {timeRemaining.minutes}</strong><span className='landing-two-span_Days'>Mins</span></li>
                        <li className='landing-two-li' ><strong class="landing-two-DAsh">:</strong></li>
                        <li className='landing-two-li'   ><strong> {timeRemaining.seconds}</strong><span className='landing-two-span_Days'>Secs</span></li>
                      </>}</ul>
                  <div className="landing-two-Discount_Value ">
                    {data.discount > 0 && (
                      <>
                        <strong>{data.discount}%</strong>
                        <span>{data.Base.sale}</span>
                      </>
                    )}
                  </div>

                </div>
                {!timeRemaining.expired && (
                  <div className="landing-two-btn_wrap pb-0">

                    {!timeRemaining.expired && (
                      <div className="landing-two-btn_wrap pb-0">
                        {data.is_login === true ? (
                          <Link to={`/Pay_LiveCourse/${data.id}`}>
                            <a className="landing-two-btn border_dark"  ><span>{data.Base.Countdown_Button}</span></a>
                          </Link>
                        ) : (
                          <Link to={`/SignInUp_LiveCourse/${data.id}`}>
                            <a className="landing-two-btn border_dark"  ><span>{data.Base.Countdown_Button}</span></a>
                          </Link>
                        )}
                      </div>
                    )}

                    <p className='landing-two-P'>{data.Base.Countdown_P}</p>
                  </div>
                )}
              </div></div></div></div>
      </section>
    </>
  );
}

export default CountdownTimer;
