import axios from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import Config from '../../config';

const baseURL = `${Config.baseURL}/Users`;

// Get tokens from local storage
let accessToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : "";
let refreshToken = localStorage.getItem('refresh_token') ? JSON.parse(localStorage.getItem('refresh_token')) : "";

const AxiosInstance = axios.create({
   baseURL: baseURL,
   headers: { Authorization: accessToken ? `Bearer ${accessToken}` : "" },
});

AxiosInstance.interceptors.request.use(async req => {
   if (accessToken) {
      req.headers.Authorization = `Bearer ${accessToken}`;

      try {
         const user = jwtDecode(accessToken);
         const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  
         if (!isExpired) return req;

 
         const resp = await axios.post(`${baseURL}/token/refresh/`, { refresh: refreshToken });
         const newAccessToken = resp.data.access;
         const newRefreshToken = resp.data.refresh;   

    
         localStorage.setItem('token', JSON.stringify(newAccessToken));
         localStorage.setItem('refresh_token', JSON.stringify(newRefreshToken));

 
         req.headers.Authorization = `Bearer ${newAccessToken}`;

         // // طباعة بيانات الـ token الجديد (للتجربة)
         // const newUser = jwtDecode(newAccessToken);
         // console.log('New Access Token Data:', newUser);
         // console.log(`User Type: ${newUser.user_type}`);
         // console.log(`Full Name: ${newUser.full_name}`);
         // console.log(`Is Active: ${newUser.is_active}`);

         return req;
      } catch (error) {
         console.error('Token refresh failed:', error);
         localStorage.removeItem('token'); 
         localStorage.removeItem('refresh_token');
         // window.location.href = '/login';  
         return Promise.reject(error);
      }
   } else {
      req.headers.Authorization = "";
      return req;
   }
});

export default AxiosInstance;