 
// import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
// import React, { useEffect, useRef, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Config from '../../config';
// import AxiosInstance from '../../desing-system/Authentication/AxiosInstance';
// import Loading from '../../desing-system/components/Loading';
// import RoomAccessError from './ RoomAccessError/ RoomAccessError';
// import './Room.css'; // Move inline styles to this CSS file.

// export default function Room() {
//   const { course_id, roomId } = useParams();
//   const [isLoading, setIsLoading] = useState(true);
//   const [data, setData] = useState(null);
//   const meetingContainerRef = useRef(null);

//   const appID = 82769731;   
//   const serverSecret  = "e7521f7eee05ed57642d6a7827f34a3c";  
 

//   const fetchCourse = async () => {
//     try {
//       if (!course_id || !roomId) return;
//       const response = await AxiosInstance.get(`${Config.baseURL}/meetings/meeting_Room/${course_id}/${roomId}/`);
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching course:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCourse();
//   }, [course_id, roomId]);

//   useEffect(() => {
//     if (!isLoading && meetingContainerRef.current && data) {
//       const { id, user_full_name, maxUsers } = data;
//       const userID = Math.floor(Math.random() * 10000 + id) + "";
//       const userName = user_full_name + userID;

//       const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
//         appID,
//         serverSecret,
//         roomId,
//         userID,
//         userName
//       );

//       try {
//         const zp = ZegoUIKitPrebuilt.create(kitToken);
//         if (zp && meetingContainerRef.current) {
//           zp.joinRoom({
//             container: meetingContainerRef.current,
//             scenario: {
//               mode: ZegoUIKitPrebuilt.VideoConference,
//             },
//             turnOnMicrophoneWhenJoining: true,
//             turnOnCameraWhenJoining: true,
//             showMyCameraToggleButton: true,
//             showMyMicrophoneToggleButton: true,
//             showAudioVideoSettingsButton: true,
//             showScreenSharingButton: true,
//             showTextChat: true,
//             showUserList: true,
//             maxUsers: maxUsers,
//             layout: 'Sidebar',
//             showLayoutButton: true,
//             screenSharingConfig: {
//               resolution: ZegoUIKitPrebuilt.ScreenSharingResolution_Auto,
//             },
//           });
//         }
//       } catch (error) {
//         console.error("Error joining room:", error);
//       }
//     }
//   }, [isLoading, roomId, data]);

//   if (isLoading) {
//     return <Loading />;
//   }

//   if (!data || !data.active || !data.is_enrolled) {
//     return <RoomAccessError/>;
//   }
//   return (
//     <div className="room-container">
//       {/* <h3
//    style={{
//     position: 'fixed', 
//     top: '50vh', 
//     left: ' 20px',  
//     color: 'red',
//     zIndex: 1000, 
//     backgroundColor: 'rgba(41, 39, 39, 0.8)',  
//     padding: '5px 10px',  
//     borderRadius: '5px',  
//     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',  
//   }}
   
// >
//   {data.user_full_name} : {data.id}
// </h3> */}
//       <div
//         className="meeting-container"
//         ref={meetingContainerRef}
//       ></div>
//     </div>
//   );
// }

import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Config from '../../config';
import AxiosInstance from '../../desing-system/Authentication/AxiosInstance';
import Loading from '../../desing-system/components/Loading';
import RoomAccessError from './ RoomAccessError/ RoomAccessError';
import './Room.css';
export default function Room() {
  const { course_id, roomId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const meetingContainerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const appID = 82769731;   
  const serverSecret  = "e7521f7eee05ed57642d6a7827f34a3c";  
 
  // Check if the device is mobile
  useEffect(() => {
    const checkDevice = () => {
      setIsMobile(window.innerWidth <= 768); // يعتبر الهاتف إذا كان العرض أقل من أو يساوي 768px
    };
    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  const fetchCourse = async () => {
    try {
      if (!course_id || !roomId) return;
      const response = await AxiosInstance.get(`${Config.baseURL}/meetings/meeting_Room/${course_id}/${roomId}/`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching course:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCourse();
  }, [course_id, roomId]);

  useEffect(() => {
    if (!isLoading && meetingContainerRef.current && data) {
      const { id, user_full_name, maxUsers } = data;
      const userID = Math.floor(Math.random() * 10000 + id) + '';
      const userName = user_full_name + userID;

      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomId,
        userID,
        userName
      );

      try {
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        if (zp && meetingContainerRef.current) {
          zp.joinRoom({
            container: meetingContainerRef.current,
            scenario: {
              mode: ZegoUIKitPrebuilt.VideoConference,
            },
            turnOnMicrophoneWhenJoining: true,
            turnOnCameraWhenJoining: true,
            showMyCameraToggleButton: true,
            showMyMicrophoneToggleButton: true,
            showAudioVideoSettingsButton: true,
            showScreenSharingButton: true,
            showTextChat: true,
            showUserList: true,
            maxUsers: maxUsers,
            layout: isMobile ? 'Grid' : 'Sidebar', // تخطيط مختلف للهاتف
            showLayoutButton: true,
            screenSharingConfig: {
              resolution: ZegoUIKitPrebuilt.ScreenSharingResolution_Auto,
            },
          });
        }
      } catch (error) {
        console.error('Error joining room:', error);
      }
    }
  }, [isLoading, roomId, data, isMobile]);

  if (isLoading) {
    return <Loading />;
  }

  if (!data || !data.active || !data.is_enrolled) {
    return <RoomAccessError />;
  }

  return (
    <div className="room-container">
      <div
        className={`meeting-container ${isMobile ? 'mobile-layout' : ''}`}
        ref={meetingContainerRef}
      ></div>
    </div>
  );
}
 