import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa'; // أيقونة التحذير
import './RoomAccessError.css';
export default function RoomAccessError() {
  return (
    <div className="error-container">
      <FaExclamationTriangle className="error-icon" />
      <p className="error-message">
        لا يمكن الوصول لهذه الغرفة ربما أنك غير مسجل في هذه الدورة أو تم إغلاق الغرفة.
      </p>
    </div>
  );
}